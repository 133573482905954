import React from "react"
import { graphql } from "gatsby"
import { SEO, Layout, Container, PortableText } from "../components/index"

// Declaring query here allows us to shadow components
export const query = graphql`
  query ($slug: String!) {
    sanityPages(slug: { current: { eq: $slug } }) {
      templateKey
      title
      description
      _rawSections
      slug {
        current
      }
    }
  }
`

const DefaultTemplate = ({ data }) => {
  let page = data.sanityPages
  let { title } = page

  return (
    <Layout small>
      {title && <h1>{title}</h1>}
      {/*{description && <h2>{description}</h2>}*/}
      <PortableText small blocks={page._rawSections} />
    </Layout>
  )
}

export default DefaultTemplate

export function Head({ data }) {
  let { title, description, templateKey } = data.sanityPages
  let captcha = false
  if (templateKey === "contact") {
    captcha = true
  }

  return <SEO title={title} description={description}></SEO>
}
